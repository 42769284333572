<template>
  <div class="trips-groups-list">
    <div class="text-h5 font-weight-bold mb-5">VAT</div>
    <v-card class="pa-7 pb-10 d-flex align-center flex-column" width="100%" outlined>
      <v-card flat tile max-width="522" color="width100 mx-auto">
        <div class="font-weight-bold text--text mb-4">Select period to create a report</div>
        <div class="d-flex">
          <div class="mr-3 width100">
            <div class="mb-2">Year</div>
            <v-select
              class="field46"
              v-model="year"
              placeholder="Select year"
              :items="yearList"
              outlined
              dense
              required
              color="primary"
              height="46"
            ></v-select>
          </div>
          <div class="ml-3 width100">
            <div class="mb-2">Quarter</div>
            <v-select
              class="field46"
              v-model="quarter"
              placeholder="Select quarter"
              :items="quarterList"
              outlined
              dense
              required
              color="primary"
              height="46"
            ></v-select>
          </div>
        </div>
        <div class="text-center mt-7">
          <v-btn
            :href="`${link}/platform/api/v1/reports/vat?year=${year}&quarter=${quarter.toLowerCase()}&t=${token}`"
            :disabled="!year || !quarter"
            depressed
            height="52"
            width="216"
            class="primary"
          >
            <v-icon class="mr-2 mt-1">mdi-tray-arrow-down</v-icon> Export report
          </v-btn>
        </div>
      </v-card>
    </v-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      year: '',
      quarter: '',
      yearList: [],
      quarterList: ['Q1', 'Q2', 'Q3', 'Q4'],
      link: window.origin,
      token: localStorage.getItem('jwt_token'),
      error: [],
    };
  },
  mounted() {
    for (let i = 2017; i < new Date().getFullYear() + 1; i++) {
      this.yearList.push(i);
    }
  },
};
</script>
